<template>
  <div>
    <div class="row justify-content-center mt-2">
      <div class="col-md-12">
        <b-overlay :show="loading">
          <div class="p-2">
            <b-alert variant="success" v-model="showDismissibleAlert" show dismissible fade><b>{{ $t('globalTrans.a_otp_send_your_mobile') }}</b> </b-alert>
            <b-alert :show="errorAlert" variant="" style="padding:5px" dismissible fade class="alert alert-danger">
              <div class="iq-alert-icon">
                <i class="ri-information-line"></i>
              </div>
              <div class="iq-alert-text">{{$t('authentication.otp_is_invalid')}}</div>
            </b-alert>
            <h2 class="col-lg-12 text-center" v-if="parseInt(timerData) > 0">{{$t('globalTrans.timer')}} : {{ timerData | minutesAndSeconds }}</h2>
            <h2 class="col-lg-12 text-center" v-else>{{$t('portal.please_resent_otp')}}</h2>
            <!-- <p class="text-center alert alert-success font-weight-bold" style="font-size:12px; font-size: 12px; padding: 4px;margin-bottom: -8px;" v-if="warnAlert" >OTP sent in your phone whose validity is 3 minutes!</p> -->
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(otpVerifySubmit)" @reset.prevent="reset" autocomplete="off">
                <ValidationProvider name="OTP" vid="otp_code" rules="required|min:3">
                  <b-form-group style="margin-bottom:0px"
                    class="row"
                    label-cols-sm="12"
                    label-for="otp_code"
                    slot-scope="{ valid, errors }"
                  >
                    <b-form-input
                      id="otp_code"
                      v-model="user.otp_code"
                      placeholder="Enter OTP"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <a v-if="parseInt(timerData) <= 0" href="#" @click="resendCode()" style="margin-top:0px;font-size:12px" class="text-primary">{{ $t('globalTrans.resend_code') }}</a>
                  <br/>
                  <br/>
                  <div class="row">
                      <div class="col-md-6 offset-md-3 text-center">
                        <button class="btn btn-primary btn-block" type="submit">{{ $t('globalTrans.verify') }}</button>
                      </div>
                  </div>
              </b-form>
            </ValidationObserver>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<style>
  .alert .close {
    color: green !important;
  }
</style>
<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { otpVerify, resendOtp, sendOtpDelete } from '../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
const FIVE_MINITES = 60 * 5

export default {
  components: { ValidationObserver, ValidationProvider },
   props: ['timer', 'user'],
  filters: {
    minutesAndSeconds (value) {
      var minutes = Math.floor(parseInt(value, 10) / 60)
      var seconds = parseInt(value, 10) - minutes * 60
      return `${minutes}:${seconds}`
    }
  },
  data: () => ({
    loading: false,
    defaultValue: '',
    warnAlert: true,
    countShow: false,
    errorAlert: false,
    otpSentFirst: true,
    showDismissibleAlert: true,
    FIVE_MINITES: FIVE_MINITES,
    timerData: 0,
    errorMsg: ''
  }),
  created () {
    this.timerData = this.timer
  },
  mounted () {
    setInterval(() => {
      if (this.timerData > 0) {
        this.timerData -= 1
      }
      if (this.timerData === 0) {
        if (this.otpSentFirst) {
          this.otpDelete()
          this.otpSentFirst = false
        }
      }
    }, 1000)
  },
  methods: {
    async otpVerifySubmit () {
      try {
        this.loading = true
        const result = await RestApi.postData(authServiceBaseUrl, otpVerify, this.user)
        if (result.success) {
          // const notification = result.notification
          // this.$socket.emit('send-notification', notification)
          this.$router.push('/auth/sign-up-success')
        } else {
          if (result.message) {
            this.errorAlert = true
            this.errorMsg = result.message
          } else {
            this.errorAlert = false
            this.$refs.form.setErrors(result.errors)
          }
        }
      } catch (error) {
        if (error) {
          //
        }
        this.errorAlert = true
        this.errorMsg = 'Failed to verify. Please, try again.'
      }

      this.loading = false
    },
    async resendCode () {
      try {
        this.loading = true
        const result = await RestApi.postData(authServiceBaseUrl, resendOtp, this.user)
        if (result.success === true) {
          this.timerData = 60 * 5
          this.user.otp_code = ''
          this.showDismissibleAlert = true
          this.errorAlert = false
          this.warnAlert = true
          this.$refs.form.reset()
        } else {
          if (result.message) {
            this.errorAlert = true
            this.warnAlert = false
            this.errorMsg = result.message
          }
        }
      } catch (error) {
        if (error) {
          //
        }
        this.errorAlert = true
        this.warnAlert = false
        this.errorMsg = 'Failed to resend code. Please, try again.'
      }
      this.loading = false
    },
    async otpDelete () {
      let result = null
      const ottpSend = {
        mobile_no: this.user.mobile_no
      }
      result = await RestApi.postData(authServiceBaseUrl, sendOtpDelete, ottpSend)
      if (result.success) {
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Not Matched'
        })
      }
    }
  }
}
</script>

<style scoped>
        .card{border: 0px!important;}
        .card-header{background-color: #c7cef8 !important; border-bottom: 0px!important;}
        .btn-custom{background-color: #596DE1; color: #fff;}
</style>
